.top-bar {
  display: flex;
  height: 20vw;
  width: 100vw;
  font-family: "Inter", sans-serif;
  background-color: #ffffff;
}

.mobile-links {
  padding: 15vw 10vw 0;
}
.mobile-links hr {
  background-color: #ffffff;
  height: 0.1px;
  width: 90%;
  margin-left: 0%;
  margin-bottom: 5vw;
}

.mobile-links a {
  font-weight: 600;
  color: #ffffff;
  text-decoration: none;
}

.hide-menu {
  display: none;
}

@keyframes slidein {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes slideout {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
  }
}

.show-menu {
  animation: slidein 0.5s;
  position: fixed;
  top: 20vw;
  width: 100%;
  height: 100%;
  background-image: url('../img/backgroundOptions.png');
  background-repeat: no-repeat;
  background-size: 100%;
}

.hide-menu {
  animation: slideout 0.5s;
  position: fixed;
  top: 20vw;
  width: 100%;
  height: 100%;
  background-color: #2c8ffe;
  z-index: 999999;
}

.phone-button {
  padding-top: 7vw;
  padding-right: 10vw;
}
.phone-button img {
  width: 5vw;
}

.mobile-display {
  padding-top: 7vw;
  padding-right: 7vw;
}
.mobile-display img {
  width: 7vw;
}

.mobile-no-display {
  display: none;
}

.img-container-top-bar {
  width: 100vw;
}
.img-container-top-bar img {
  width: 30vw;
  margin-left: 5px;
  margin-top: 5vw;
}

.switch-container {
  display: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 10vw;
  margin-top: 5vw;
}
.switch-option:last-child {
  padding-left: 2vw;
  padding-right: 5vw;
}
.switch-option:first-child {
  padding-right: 2vw;
}
.call-text {
  display: none;
}

@media screen and (min-width: 1001px) {
  .top-bar {
    height: 8vw;
  }
  .img-container-top-bar img {
    width: 9vw;
    margin-left: 2vw;
    margin-top: 0.75vw;
  }
  .switch-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 5vw;
    margin-top: 0.5vw;
    margin-right: 2vw;
  }
  .switch-option:last-child {
    padding-left: 1vw;
    padding-right: 3vw;
  }
  .switch-option:first-child {
    padding-right: 1vw;
  }
  .show-menu {
    animation: none;
    top: 1.5vw;
    left: 63.5vw;
    width: 100%;
    height: 5%;
    background-color: transparent;
    background-image: none;
  }
  .desktop-links a {
    font-weight: 500;
    color: #2c8ffe;
    text-decoration: none;
    padding-right: 2vw;
    font-size: 1.3vw;
  }
  .phone-button {
    background-color: #2c8ffe;
    border-radius: 20px;
    padding-top: 0.25vw;
    padding-right: 0;
    margin-right: 38vw;
    display: grid;
    grid-template-columns: 2vw 1fr;
    height: 2.25vw;
    width: 9.5vw;
    margin-top: 1.15vw;
  }

  .floating-button .call-text {
    display: block;
    color: #ffffff;
    font-size: 1.25vw;
    font-weight: 600;
    margin-left: 1vw;
    margin-top: 0.25vw;
  }
  .phone-button img {
    width: 1.2vw;
    color: #ffffff;
    margin-right: 2vw;
    grid-column: 1;
    margin-left: 1vw;
    margin-top: 0.2vw;
  }
  .floating-button {
    text-decoration: none;
  }
}
