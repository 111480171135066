.how-we-do{
    padding-top: 30%
}

.how-we-do-it-title{
    margin: 0% 0% 5% 10%;
    align-items: left;
    font-family: "Bebas Neue";
    font-weight: 400;
    width: 80%;
    font-size: 7.25rem;
    color: #ffffff;
    line-height: 6rem;
}

.how-we-do-it-p{
    margin: 0% 0% 7% 10%;
    align-items: left;
    font-family: "Montserrat";
    font-weight: 400;
    width: 82%;
    font-size: 1.75rem;
    color: #ffffff;
    line-height: 2rem;
}

@media screen and (min-width: 1001px) {
    .how-we-do{
        padding-top: 13%;
        display: flex;
    }

    .how-we-do-it-title{
        margin: 0.5% 0% 0% 5%;
        text-align: left;
        width: 50%;
        font-size: 11vw;
        line-height: 8.5vw;
    }

    .how-we-do-it-p{
        margin: 0% 0% 2% 10%;
        align-items: left;
        width: 65%;
        font-size: 1.55vw;
        color: #ffffff;
        line-height: 2vw;
    }

    .logos-position{
        display: flex;
        margin-left: 9%;
    }

    .logo-google{
        width: 15%;
    }

    .logo-meta{
        width: 15%;
    }

    .logo-tiktok{
        width: 15%;
    }
}