.succes-stories{
    padding-top: 25%;
    background-color: #2c88f7;
}

.succes-stories-title{
    color: #ffffff;
    font-weight: 700;
    margin: 0% 0% 0% 10%;
    font-size: 4rem;
    font-family: "Urbanist";
    width: 80%;
}

.success-stories-images-position{
    display: flex;
    margin: 0% 0% -8% 0%;
    /* position: fixed; */
}

.success-stories-images-adt{
    width: 35%;
    margin-left: 10%;

}

.success-stories-images-sunpower{
    margin-left: 5%;
    width: 50%;
}

.success-stories-images{
    width: 50%;
}

@media screen and (min-width: 1001px) {
    .succes-stories{
        padding-top: 10%;
        background-color: #2c8ffe;
    }
    
    .succes-stories-title{
        font-weight: 700;
        margin: 0% 0% 3% 5%;
        font-size: 4.5vw;
    }
    
    .success-stories-images-position{
        display: flex;
        margin: 0% 0% 0% 0%;
        justify-content: center;
        /* position: fixed; */
    }
    
    .success-stories-images-adt{
        width: 13%;
        margin-left: 3%;
        margin-right: 3%;
    }
    
    
    .success-stories-images{
        width: 23%;
    }
    
    .success-stories-images-2{
        width: 21%;
        margin-top: -5%;
        margin-right: 1.5%;
    }

    .success-stories-images-brinks{
        width: 21%;
        margin-top: -3%;
        margin-left: -2%;

    }
}