input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background-image: url('../img/backgroundMobile.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: 20vw;
  background-color: #2c88f7;
}

.App {
  display: grid;
  grid-template-rows: 20vw 1fr;
}
hr {
  width: 90vw;
}

.top-bar-position {
  display: grid;
  position: fixed;
  width: 100vw;
  margin-left: 0%;
  z-index: 99;
  overflow: hidden;
  background-color: transparent;
  height: 20vw;
  /* -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25); */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.top-bar-position-white {
  display: grid;
  position: fixed;
  width: 100vw;
  margin-left: 0%;
  z-index: 99;
  overflow: hidden;
  background-color: white;
  height: 20vw;
  /* -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25); */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.more-selling-position {
  display: grid;
  grid-row: 2/2;
  padding-top: 15vw;
  background-color: #f4f4f4;
  /* margin-top: 20vw; */
}

@media screen and (min-width: 1001px) {
  body {
    background-image: url('../img/backgroundDesk.png');
    background-position-y: -25vw;
  }

  .top-bar-position {
    height: 5vw;
  }
  .top-bar-position-white {
    background-color: white;
    height: 6vw;
  }
  .more-selling-position {
    display: grid;
    grid-row: 2/2;
    padding-top: 5vw;
  }
  hr {
    width: 85vw;
  }

  noscript{
    display: none;
  }
  script{
    display: none;
  }

}
