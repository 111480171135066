input[type="submit"],
select,
button {
  -webkit-appearance: none;
}
.contact-us {
  font-family: "Inter", sans-serif;
  text-align: center;
  background-color: #2c88f7;
}
#contact-us {
  padding-top: 25%;
}
.contact-us-title {
  color: #ffffff;
  font-weight: 600;
  margin: 0% 0% 0% 0%;
  font-size: 2.4rem;
  font-family: "Montserrat";
}
.contact-us-subtitle {
  font-size: 1.25rem;
  color: #ffffff;
  font-weight: 400;
  margin: 0% 0% 5% 0%;
  font-family: "Montserrat";
}

.contact-us form {
  border: none;
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: left;
  padding: 2vw;
}

.contact-us form input {
  padding: 3vw 0vw 3vw 3vw;
  margin: 0 5vw 2vw;
  border-radius: 11px;
  border-color: #ffffff;
  border-style: solid;
  border-width: 0.5vw;
  color: #ffffff;
  background-color: transparent;
  font-family: "Urbanist";
  font-size: 1.25rem;
}

.contact-us form input::placeholder {
  color: #ffffff;
  font-size: 1.25rem;
  font-family: "Urbanist";
}

.contact-us form textarea::placeholder {
  font-family: "Urbanist";
  color: #ffffff;
  font-size: 1.25rem;
}
.contact-us form textarea {
  padding: 2vw 0vw 12vw 3vw;
  margin: 0 5vw -2vw;
  border-radius: 11px;
  border-color: #ffffff;
  border-style: solid;
  border-width: 0.5vw;
  color: #ffffff;
  background-color: transparent;
  font-family: "Urbanist";
  font-size: 1.25rem;
}


.contact-us form button {
  background-color: #ffffff;
  color: #2c88f7;
  text-align: center;
  font-family: "Urbanist";
  font-size: 5vw;

  font-weight: 400;
  cursor: pointer;
  border-radius: 11px;
  border: 0;
  width: 90%;
  margin-left: 5%;
  /* margin-bottom: 5vw; */
  padding: 3vw 0vw 3vw 0vw;
}

textarea{
  font-family:inherit;
  resize: none;
}

.contact-us button span{
  margin: -1% 0% -1% 16%;
}

/* spinner css */

.lds-ring-loader{
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 9%;
  height: 4.5%;
  margin: 0% 0% 0% 0%;
  border: 8px solid #2c88f7;
  border-radius: 100%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2c88f7 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* pop up css */

.contact-us .checkGreen {
  display: block;
  text-align: center;
  align-items: center;
  margin-top: 50px;
}

.contact-us .checkGreen-container{
  min-width: 150px;
  min-height: 150px;
}

/* Esto es el css de los mensajes de error que da el formulario */

.message-es {
  justify-content: center;
  display: flex;
  position: absolute;
  text-align: center;
  margin-top: 10%;
}
.message-en {
  justify-content: center;
  display: flex;
  position: absolute;
  text-align: center;
  margin-top: 10%;
}

.error-symbol{
  width: 10%;
  margin-top: -3.5%;
  margin-right: 1%;
  margin-left: -5%;
}

@media screen and (min-width: 1001px) {
  .contact-us {
    margin-top: 10vw;
    background-color: #2c8ffe;
    justify-content: center;
  }

  #contact-us {
    padding-top: 15%;
    margin-top: -10vw;
  }

  .contact-us-title {
    margin: 0% 0% 0% 0%;
    font-size: 3.25vw;
  }

  .contact-us-subtitle {
    font-size: 2vw;
    margin: 0% 0% 0% 0%;
  }

  .contact-us form {
    width: 50vw;
    text-align: left;
    padding: 2vw;
  }

  .contact-us form input {
    padding: 1vw 0vw 1vw 1.5vw;
    margin: 0 5vw 2vw;
    border-width: 0.15vw;
    color: #ffffff;
    font-size: 1.5vw;
  }

  .contact-us form textarea {
    padding: 1.5vw 0vw 8vw 1.5vw;
    margin: 0 5vw -2vw;
    border-radius: 11px;
    border-width: 0.15vw;
    font-size: 1.5vw;
  }

  .contact-us form input::placeholder {
    font-size: 1.25vw;
  }
  
  .contact-us form textarea::placeholder {
    font-size: 1.25vw;
  }

  .contact-us form button {
    margin-top: 7%;
    font-size: 1.75vw;
    width: 80%;
    margin-left: 10%;
    padding: 1vw 0vw 1vw 0vw;
  }

  textarea{
    font-family:inherit;
    resize: none;
  }
  .message-es {
    position: inherit;
    width: 100%;
    left: 4%;
    text-align: center;
  }
  .message-en {
    position: inherit;
    width: 100%;
    left: 4%;
    text-align: center;
  }

  .error-symbol{
    width: 7%;
    height: 10%;
    margin-top: -5%;
    margin-right: 1%;
    margin-left: -5%;
  }

  /* spinner css */

.lds-ring-loader{
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 2.5%;
  height: 5%;
  margin: 0% 0% 0% 0%;
  border: 8px solid #2c8ffe;
  border-radius: 100%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2c8ffe transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.contact-us button span{
  margin: 0% 0% 0% 12%;
}

}
