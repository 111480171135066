.footer {
  text-align: center;
  color: #ffffff;
  font-family: "Inter", sans-serif;
  background-color: #2c88f7;
  height: fit-content;
  padding-top: 20%;
}
.footer img {
  transform: translate(-38vw, 10vw);
}
.footer-country {
  /* display: flex; */
  flex-wrap: wrap;
  justify-content: left;
  gap: 0vw;
  padding: 7.5vw;
}
.box-country {
  width: 40vw;
  text-align: left;
}
.box-country:last-child {
  width: 50vw;
}
.box-country h5 {
  font-weight: 600;
  font-size: 5vw;
  margin-bottom: 0;
}
.box-country p {
  padding-top: 0;
  font-size: 3.5vw;
}

.footer span {
  font-size: 4.5vw;
  line-height: 18px;
  font-weight: 600;
}

.footer a {
  text-decoration: none;
  color: inherit;
}

.item {
  text-align: center;
  padding-top: 2vw;
  padding-bottom: 2vw;
}
.item:last-child {
  padding-bottom: 10vw;
}
.short-hr {
  width: 70vw;
}
.separator {
  display: none;
}

@media screen and (min-width: 1001px) {
  .footer{
    background-color: #2c8ffe;
    padding-top: 10%;
  }

  .footer span {
    display: block;
    font-size: 1.5vw;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
    height: min-content;
  }
  .footer img {
    transform: translate(-44vw, 3.5vw);
    width: 4%;
  }

  .item {
    height: min-content;
    align-items: center;
  }
  .item:last-child {
    padding-bottom: 3vw;
  }
  .footer-country {
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;
    gap: 2vw;
    padding: 0vw 0vw 0vw 10vw;
    margin-left: -2%;
  }
  .box-country {
    width: 15vw;
    text-align: left;
    margin-top: -2vw;
  }
  .box-country:nth-child(4) {
    width: 15vw;
  }
  .box-country:last-child {
    width: 15vw;
  }
  .box-country h5 {
    font-weight: 900;
    font-size: 1.5vw;
    margin-bottom: 0;
  }
  .box-country p {
    padding-top: 0,75vw;
    font-size: 1vw;
    font-weight: 600;
  }
  .footer-info {
    display: flex;
    justify-content: center;
    gap: 1vw;
    padding-top: 3vw;
    height: min-content;
    overflow: hidden;
  }
  .footer-info .item {
    width: 20vw;
    text-align: center;
  }
  .short-hr {
    display: none;
  }
  .separator {
    display: block;
    font-size: 2.2vw;
    padding-top: 1vw;
    margin-left: -3vw;
  }
  .separator-country {
    display: block;
    font-family: "Ubuntu";
    font-size: 5vw;
    font-weight: 100;
    padding-top: 0vw;
    margin-left: -3vw;
  }
}
