.masterdealer-world{
    margin-top: 13vw;
    width: 70%;
}

.sales-happen-title-es{
    margin: 0% 5% 0% 10%;
    font-family: "Urbanist";
    font-weight: 400;
    width: 85%;
    font-size: 4.25rem;
    line-height: 3.75rem;
    color: #ffffff;
}

.sales-happen-title-en{
    margin: 0% 5% 0% 10%;
    font-family: "Urbanist";
    font-weight: 400;
    width: 85%;
    font-size: 4.25rem;
    line-height: 3.75rem;
    color: #ffffff;
}

.sales-happen-subtitle-en{
    margin: 5% 0% 2% 10%;
    align-items: left;
    font-family: "Bebas Neue";
    font-weight: 400;
    width: 85%;
    font-size: 4.5rem;
    line-height: 4rem;
    color: #ffffff;
}

.sales-happen-subtitle-es{
    margin: 5% 0% 2% 10%;
    align-items: left;
    font-family: "Bebas Neue";
    font-weight: 400;
    width: 85%;
    font-size: 4.5rem;
    line-height: 4rem;
    color: #ffffff;
}

.sales-happen-p{
    margin: 0% 0% 0% 10%;
    align-items: left;
    font-family: "Montserrat";
    font-weight: 400;
    width: 82%;
    font-size: 1.75rem;
    line-height: 2rem;
    color: #ffffff;
}

@media screen and (min-width: 1001px) {
    .where-sales-happen{
        display: flex;
        width: 100%;
        flex-direction: row-reverse;
        color: #ffffff;
    }

    .sales-happen-content{
        margin: 8% 0% 0% 0%;
        align-items: left;
        width: 100%;
    }

    .sales-happen-title-en{
        margin: 0% 0% 0% 7%;
        width: 100%;
        font-size: 7.25vw;
        line-height: 6vw;
    }

    .sales-happen-title-es{
        margin: 0% 0% 0% 7%;
        width: 100%;
        font-size: 6.6vw;
        line-height: 6vw;
    }

    .sales-happen-subtitle-en{
        margin: 4% 0% 1% 7%;
        width: 70%;
        font-size: 6.6vw;
        line-height: 5.75vw;
    }

    .sales-happen-subtitle-es{
        margin: 3% 0% 1% 7%;
        width: 75%;
        font-size: 5.1vw;
        line-height: 4.5vw;
    }

    .sales-happen-p{
        margin: 0% 0% 0% 7%;
        width: 70%;
        font-size: 1.55vw;
        line-height: 2vw;
    }

    .masterdealer-world{
        margin-top: 4vw;
        width: 35%;
        transform: scaleX(-1);
    }
}