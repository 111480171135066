.modal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.60);
  display: none;
  justify-content: center;
  align-items: center;
}

/* css del contenido del popup de agradecimiento */

.modal-container {
  position: relative;
  min-width: 400px;
  min-height: 550px;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 2%;
  overflow-x: hidden;
}

.title-en{
  position: absolute;
  width: 100%;
  top: 210px;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}

.title-es{
  position: absolute;
  width: 100%;
  top: 210px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.modal-container h2{
  position: absolute;
  width: 100%;
  height: 24px;
  top: 325px; 
  margin-top: 40px;
  font-size: 25px;
  font-weight: 400;
  text-align: center;  
}

.modal-close button {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.modal.is-open {
  display: flex;
}

@media screen and (min-width: 700px){
  .modal-container {
    position: relative;
    min-width: 650px;
    min-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .title-en{
    position: absolute;
    display: flex;
    width: 450px;
    top: 195px;
    font-size: 45px;
    font-weight: 700;
    left: 15%;
  }

  .title-es{
    position: absolute;
    width: 100%;
    top: 195px;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
  }

  .modal-container h2{
    position: absolute;
    width: 100%;
    height: 24px;
    top: 320px; 
    margin-top: 40px;
    font-size: 31px;
    font-weight: 400;
    text-align: center; 
    right: 0%;
  }
}

@media screen and (min-width: 1250px) {

  .modal-container {
    position: relative;
    min-width: 1100px;
    min-height: 550px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .title-en{
    position: absolute;
    display: flex;
    width: 450px;
    top: 185px;
    font-size: 55px;
    font-weight: 700;
    left: 30%;
  }
  
  .title-es{
    position: absolute;
    width: 800px;
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    left: 14%;
  }

  .modal-container h2{
    position: absolute;
    width: 100%;
    height: 24px;
    top: 350px; 
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    right: 0%;  
  }
}