.our-platform{
    padding-top: 30%;
}

.our-platform-title{
    color: #ffffff;
    font-weight: 700;
    margin: 0% 0% 0% 10%;
    font-size: 4rem;
    font-family: "Urbanist";
}

.img-platform-mobile{
    width: 90%;
    margin-left: 10%;
}

.our-platform-content{
    margin: 1% 0% 10% 10%;
    align-items: left;
    font-family: "Montserrat";
    font-weight: 400;
    width: 86%;
    font-size: 1.75rem;
    color: #ffffff;
    line-height: 2rem;
}

@media screen and (min-width: 1001px) {
    .our-platform{
        padding-top: 10%;
    }

    .our-platform-title{
        margin: 0% 0% 0% 5%;
        font-size: 6vw;
        font-family: "Urbanist";
    }
    
    .img-platform-mobile{
        width: 90%;
        margin-left: 10%;
    }

    .img-platform-dashboard{
        width: 70%;
        height: 50%;
        margin-left: 0%;
    }

    .our-platform-container{
        display: flex;
    }
    
    .our-platform-content-dashboard{
        margin: 28% 0% 0% -20%;
        align-items: left;
        font-family: "Montserrat";
        font-weight: 400;
        width: 100%;
        font-size: 1.55vw;
        color: #ffffff;
        line-height: 2vw;
    }

    .our-platform-arrow-dashboard{
        margin: 20% 0% 0% -20%;
        align-items: left;
        width: 50%;
    }

    .our-platform-container-reverse{
        display: flex;
        flex-direction: row-reverse;
    }

    .img-platform-masterapp{
        width: 25%;
        height: 50%;
        margin-right: 5%;
        margin-top: -2%;
    }

    .our-platform-arrow-masterapp{
        margin: 15% 0% 0% 15%;
        align-items: left;
        width: 10%;
        transform: rotate(340deg) scaleX(-1);
    }

    .our-platform-content-masterapp{
        margin: 0% 0% 0% 0%;
        align-items: left;
        font-family: "Montserrat";
        font-weight: 400;
        width: 95%;
        font-size: 1.55vw;
        color: #ffffff;
        line-height: 2vw;
    }

    .our-platform-number-title{
        margin: 0% 0% 0% 0%;
        font-size: 8vw;
        font-family: "Montserrat";
        color: #ffffff;
        font-weight: 900;
    }

    .our-platform-platform-title{
        margin: -3% 0% 0% 0%;
        font-size: 6vw;
        font-family: "Bebas Neue";
        color: #ffffff;
        font-weight: 400;
    }

    .img-platform-assigner{
        width: 60%;
        margin-left: 0%;
        margin-top: -4%;
    }

    .our-platform-number-title-assigner{
        margin: -5% 0% 0% -5%;
        font-size: 8vw;
        font-family: "Montserrat";
        color: #ffffff;
        font-weight: 700;
    }

    .our-platform-platform-assigner-title{
        margin: -3% 0% 0% -5%;
        font-size: 6vw;
        font-family: "Bebas Neue";
        color: #ffffff;
        font-weight: 400;
    }

    .our-platform-content-assigner{
        margin: 0% 0% 0% -5%;
        align-items: left;
        font-family: "Montserrat";
        font-weight: 400;
        width: 90%;
        font-size: 1.55vw;
        color: #ffffff;
        line-height: 2vw;
    }

    .our-platform-arrow-assigner{
        margin: 5% 0% 0% 43%;
        align-items: left;
        width: 30%;
        transform: rotate(30deg);
    }

    .img-platform-geopanel{
        width: 60%;
        margin-left: 0%;
        margin-top: -8%;
        margin-bottom: -10%;
    }

    .our-platform-number-title-geopanel{
        margin: 4% 0% 0% 13%;
        font-size: 8vw;
        font-family: "Montserrat";
        color: #ffffff;
        font-weight: 700;
    }

    .our-platform-platform-geopanel-title{
        margin: -3% 0% 0% 13%;
        font-size: 6vw;
        font-family: "Bebas Neue";
        color: #ffffff;
        font-weight: 400;
    }

    .our-platform-content-geopanel{
        margin: 0% 0% 0% 13%;
        align-items: left;
        font-family: "Montserrat";
        font-weight: 400;
        width: 100%;
        font-size: 1.55vw;
        color: #ffffff;
        line-height: 2vw;
    }

}