.telefono{
    font-size: 4vw;
    color: #ffffff;
    width: 100%;
    margin-top: -3%;
    background-color: #2c88f7;
    }

.mail{
    font-size: 4vw;
    color: #ffffff;
    width: 100%;
    margin-top: -3%;
    background-color: #2c88f7;
    }

.error-post{
    font-size: 4vw;
    color: #ffffff;
    width: 100%;
    margin-top: 2%;
    background-color: #2c88f7;
}

.name{
    font-size: 4vw;
    color: #ffffff;
    width: 100%;
    margin-top: 3%;
    background-color: #2c88f7;
    }

@media screen and (min-width: 1001px) {
    .telefono{
        font-size: 1.25vw;
        margin-top: -13%;
        background-color: #2c8ffe;
        }

    .mail{
        font-size: 1.25vw;
        margin-top: -20%;
        background-color: #2c8ffe;
    }

    .error-post{
        font-size: 1.25vw;
        margin-top: -9%;
        background-color: #2c8ffe;
    }

    .name{
        font-size: 1.25vw;
        margin-top: -20%;
        background-color: #2c8ffe;
    }
}